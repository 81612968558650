import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import AmplifySignCom from './AmplifySignCom';

import App from './App';
import Download from './Download';
import Admin from './Admin';
import Login from './Login';
import Login2 from './Login2';
export default function Rout() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          
            <Route path="/save">
              <AmplifyAuthenticator>
                <AmplifySignCom />
                <App />
              </AmplifyAuthenticator>
            </Route>
            <Route path="/download">
                <Download />
            </Route>
            <Route path="/admin">
              <AmplifyAuthenticator>
                <AmplifySignCom />
                <Admin />
              </AmplifyAuthenticator>
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/login2">
              <Login2 />
            </Route>
        
        </Switch>
      </div>
    </Router>
  );
}