import React, { useState, useEffect } from 'react';
import E from 'wangeditor'
import i18next from 'i18next'
import { get, post } from './util.js'
import './assets/css/bootstrap.min.css'
import './assets/css/app.min.css'
import './App.css';
import Cookies from 'js-cookie'

let editor = null
let editorArea = null
function App() {
  const [area, setArea] = useState('yohohama')
  const [section, setSection] = useState(1)
  const [content, setContent] = useState('')
  const [editStatus, setEditStatus] = useState(false);
  const [contentArea, setContentArea] = useState('')
  const [editStatusArea, setEditStatusArea] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    let userGroups = Cookies.get('groups') || '';
    setIsAdmin(userGroups.indexOf('admin') > -1 ? true : false);
    // 注：class写法需要在componentDidMount 创建编辑器
    editor = new E("#div1")
    editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      // 'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'justify',
      'quote',
      // 'emoticon',
      'image',
      'video',
      'table',
      'code',
      'splitLine',
      'undo',
      'redo',
    ]
    // 选择语言
    editor.config.lang = 'en'
    editor.i18next = i18next
    editor.config.onchange = (newHtml) => {
      setContent(newHtml)
    }
    /**一定要创建 */
    editor.create()
    // 注：class写法需要在componentDidMount 创建编辑器
    editorArea = new E("#div2")
    editorArea.config.menus = [
      'head',
      'bold',
      'fontSize',
      // 'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      'link',
      'list',
      'justify',
      'quote',
      // 'emoticon',
      'image',
      'video',
      'table',
      'code',
      'splitLine',
      'undo',
      'redo',
    ]
    // 选择语言
    editorArea.config.lang = 'en'
    editorArea.i18next = i18next
    editorArea.config.onchange = (newHtml) => {
      setContentArea(newHtml)
    }
    /**一定要创建 */
    editorArea.create()

    return () => {
      // 组件销毁时销毁编辑器  注：class写法需要在componentWillUnmount中调用
      editor.destroy()
      editorArea.destroy()
    }
  }, [])
  useEffect(() => {
    get('research/report/read_text', {
      id: 1,
      area: 'japan',
    }).then(res => {
      console.log(res);
      setContent(res.text)
    })
    get('research/report/read_text', {
      id: section,
      area: area,
    }).then(res => {
      console.log(res);
      setContentArea(res.text)
    })
  }, [])
  function modify() {
    editor.txt.html(content)
    setEditStatus(true);
  }
  function save() {
    post('research/report/write_text', {
      id: 1,
      area: 'japan',
      text: content
    }).then(res => {
      setEditStatus(false);
    })

  }
  function cancel() {
    setEditStatus(false);
  }
  function modifyArea() {
    editorArea.txt.html(contentArea)
    setEditStatusArea(true);
  }
  function saveArea() {
    post('research/report/write_text', {
      id: section,
      area: area,
      text: contentArea
    }).then(res => {
      setEditStatusArea(false);
    })
  }
  function cancelArea() {
    setEditStatusArea(false);
  }
  // 获取html方法1
  function getHtml() {
    alert(content)
  }

  // 获取html方法2
  function getHtml1() {
    alert(editor.txt.html())
  }

  // 获取text
  function getText() {
    alert(editor.txt.text())
  }
  function changeArea(e) {
    setArea(e.target.value)
    get('research/report/read_text', {
      id: section,
      area: e.target.value,
    }).then(res => {
      console.log(res);
      setContentArea(res.text)
    })
  }
  function changePic(e) {
    setSection(e.target.value)
    get('research/report/read_text', {
      id: e.target.value,
      area: area,
    }).then(res => {
      console.log(res);
      setContentArea(res.text)
    })
  }

  return (

    <section>
      <div class="row">
        <div class="col-xl-12">
          <div class="card-box pb-2">
            <h4 class="header-title mb-3">Japan</h4>

            <div>
              <div id="div1" class={editStatus ? 'show' : 'hide'}></div>
              <p dangerouslySetInnerHTML={{ __html: content }} class={!editStatus ? 'show' : 'hide'}></p>


            </div>
            {isAdmin && (
              <div class="app-container">
                <div class="btn-group mb-2 text-center" >
                  {!editStatus ? (<button
                    type="button"
                    class="btn btn-blue waves-effect waves-light margin5"
                    onClick={modify}
                  >Edit</button>) : (
                      <section>
                        <button
                          type="button"
                          class="btn btn-secondary waves-effect margin5"
                          onClick={cancel}
                        >Cancel</button>
                        <button
                          type="button"
                          class="btn btn-blue waves-effect waves-light margin5"
                          onClick={save}
                        >OK</button>
                      </section>
                    )}


                </div>
              </div>
            )}

          </div>
        </div>
      </div>

      <div class="row" >
        <div class="col-xl-12">
          <div class="card-box pb-2">
            <form role="form" class="parsley-examples" >
              <div class="form-group row">
                <label
                  class="col-2 col-form-label text-center"
                >地域：</label>
                <div class="col-4">
                  <select disabled={editStatusArea} class="form-control" value={area} onChange={changeArea}>
                    <option value="yokohama">横浜</option>
                    <option value="kyoto">京都</option>
                    <option value="kanazawa">金沢</option>
                    <option value="hiroshima">広島</option>
                    <option value="sapporo">札幌</option>
                  </select>
                </div>
                <label
                  class="col-2 col-form-label text-center"
                >画面ID</label>
                <div class="col-4">
                  <select disabled={editStatusArea} class="form-control" value={section} onChange={changePic}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>
              </div>
            </form>
            <div>
              <div id="div2" class={editStatusArea ? 'show' : 'hide'}></div>
              <p dangerouslySetInnerHTML={{ __html: contentArea }} class={!editStatusArea ? 'show' : 'hide'}></p>


            </div>
            {isAdmin && (
              <div class="app-container">
                <div class="btn-group mb-2 text-center" >
                  {!editStatusArea ? (<button
                    type="button"
                    class="btn btn-blue waves-effect waves-light margin5"
                    onClick={modifyArea}
                  >Edit</button>) : (
                      <section>
                        <button
                          type="button"
                          class="btn btn-secondary waves-effect margin5"
                          onClick={cancelArea}
                        >Cancel</button>
                        <button
                          type="button"
                          class="btn btn-blue waves-effect waves-light margin5"
                          onClick={saveArea}
                        >OK</button>
                      </section>
                    )}


                </div>
              </div>
            )}

          </div>
        </div>

      </div>

    </section>




  );
}

export default App;