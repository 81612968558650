import React from 'react';
import './App.css';

const MemberLogin = () => {
    const onAzureLogin = () => {
        window.location.href = "https://login.microsoftonline.com/0159e9d0-09a0-4edf-96ba-a3deea363c28/oauth2/v2.0/authorize?client_id=bd8d69f5-f081-4c51-82f2-d16c86643b76&response_type=token&redirect_uri=https://cbredata.com&scope=openid&response_mode=fragment&state=12345&nonce=678910";
    }
    return (
        <div slot="federated-buttons" class="member-login-container">
                <h4 class="login-button-title">CBRE社員は、緑のボタンでログイン可能</h4>
                <h6>SSO login for CBRE Employees: Please click the button below.</h6>
                <div class="login-button-container">
                    <button class="login-button-style" onClick={() => onAzureLogin()}>ログイン Login</button>
                </div><br/>
                <div class="notice"><h5>注意：</h5>
                ​ダウンロードしたデータを、当データベースの購読会員ではない社外の方に送信することや、社外のシステムに保存することは<span>禁じられています</span>。</div>
                <br/><div class="notice"><h5>WARNING:​</h5>​
<span>It is prohibited</span> to send downloaded data to individuals outside the company who are not subscribers of this database, or to store it in systems outside the company.</div>
        </div>
    )
}

export default MemberLogin;