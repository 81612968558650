import axios from 'axios'    
import md5 from 'md5'


const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
  timeout: 10000, // 设置超时时间10s
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.cbredata.com/api/v1/' : 'https://api.cbredata.com/api/v1/'   //根据自己配置的反向代理去设置不同环境的baeUrl
})
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

let httpCode = {        //http status code
  400: 'HTTP/1.1 400 Bad Request',
  401: 'HTTP/1.1 401 Unauthorized',
  403: 'HTTP/1.1 403 Forbidden',
  404: 'HTTP/1.1 404 Not Found',
  500: 'HTTP/1.1 500 Internal Server Error',
  501: 'HTTP/1.1 501 Not Implemented',
  502: 'HTTP/1.1 502 Bad Gateway',
  504: 'HTTP/1.1 504 Gateway Time-out'
}

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
    let key = 'the api key, i will decide it later';
    let timeStr = new Date().toLocaleDateString().replaceAll('/','')+new Date().getHours();
  config.headers['Authorization'] = md5(key+timeStr);
  
  if (config.method === 'get') { // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
    config.params = {
      ...config.params,
      t: new Date().getTime()
    }
  }
  // 在这里：可以根据业务需求可以在发送请求之前做些什么:例如我这个是导出文件的接口，因为返回的是二进制流，所以需要设置请求响应类型为blob，就可以在此处设置。
  if (config.url.includes('pur/contract/export')) {
    config.headers['responseType'] = 'blob'
  }
  // 我这里是文件上传，发送的是二进制流，所以需要设置请求头的'Content-Type'
  if (config.url.includes('pur/contract/upload')) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  return config
}, error=> {
  // 对请求错误做些什么
  return Promise.reject(error)
})

/** 添加响应拦截器  **/
instance.interceptors.response.use(response => {
  console.log(response)
  if (response.status === 200) {     // 响应结果里的status: ok是我与后台的约定，大家可以根据实际情况去做对应的判断
    return Promise.resolve(response.data)
  } else {
    
    return Promise.reject(response.message)
  }
}, error => {
 
  if (error.response) {     
       
    return Promise.reject(error)
  } else {
   
    return Promise.reject(error)
  }
})

/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url,
      params,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url,
      data,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}
