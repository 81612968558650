import React from 'react';
import { AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import MemberLogin from './MemberLogin';
import './App.css';

const formFields = [
    {
        type: 'username',
        required: true,
    },
    {
      type: 'password',
      required: true,
    },
  ]

const AmplifySignCom = () => {
    
    return (
        <>
            <AmplifySignIn slot="sign-in">
                <MemberLogin />
            </AmplifySignIn>
            
        </>
    )
}

export default AmplifySignCom;