import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie'
class App extends React.Component{
    constructor(props){
      super(props)
      this.state={
        test:{},
        arr:[]
      }
    }
    onSubmit() {
        this.setState({
            arr : 'Loading ...'
        });
        fetch("https://cbredata.com/python_job/index.php?act=upd&token=980c6fea0b395738b71007f85852e2b1")
        .then(res =>res.json())
        .then((data) => {
            console.log(data);
            this.setState({
                arr : data.msg
            })
            //result_msg=data.msg;
        },(error) => {
            
        })
    }

    render(){
        return (

            <section>

                <div class="row" >
                    <div class="col-xl-12">
                        <div class="card-box pb-2">
                            <h4 class="header-title mb-3">チャートグラフの更新</h4>
                            <div class="app-container text-left">
                                <div class="btn-group mb-2">
                                    <a type="button" class="btn btn-blue waves-effect waves-light margin5" href="#" onClick={() => this.onSubmit()}>submit</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-box pb-2">
                            <div class="app-container text-left">
                                {this.state.arr}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-xl-12">
                        <div class="card-box pb-2">
                            <h4 class="header-title mb-3">承認プロセス</h4>
                        </div>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-xl-12">
                        <div class="card-box pb-2">
                            <h4 class="header-title mb-3">閲覧状況</h4>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default App;