const lan = {
    ja:{
        'Sign In Account' : 'アカウントにサインイン',
        'Username' : 'メールアドレス',
        'Enter your username' : 'メールアドレスを入力してください',
        'Password' : 'パスワード',
        'Enter your password' : 'パスワードを入力してください',
        'Forgot your password?' : 'パスワードを忘れた場合',
        'Reset password' : 'パスワードのリセット',
        'No account?' : 'まだアカウントがない場合',
        'Create account' : 'アカウント作成',
        'Sign In' : 'サインイン',
        'Sign in to your account': ' ',//'アカウントにサインインします',
        'Username *': 'メールアドレス',
        'Password *': 'パスワード',
        'Reset your password': 'パスワードをリセット',
        'Back to Sign In': 'サインインに戻る',
        'Username cannot be empty': 'メールアドレスを空にすることはできません',
        'Change': '変更する',
        'Change Password': 'パスワードを変更する',
        'Verification code': '検証コード',
        'Enter code': 'コードを入力する',
        'Confirmation Code': '確認コード',
        'Enter your code': 'コードを入力してください',
        'Confirm Sign up': 'サインアップの確認',
        'Lost your code?': 'コードを紛失しましたか？',
        'Resend Code': 'コードを再送する',
        'Confirm': '確認',
        'Confirm SMS Code': 'SMSコードを確認する',
        'Confirm TOTP Code': 'TOTPコードを確認する',
        'Email Address *': 'メールアドレス *',
        'Enter your email address': 'メールアドレスを入力してください',
        'New password': '新しいパスワード',
        'Enter your new password': '新しいパスワードを入力してください',
        'Phone Number *': '電話番号 *',
        'qrcode': 'QRコード',
        '(555) 555-1212': '000-0000-0000',
        'Verify': '確認',
        'Send Code': 'コードを送信',
        'Submit': '確認',
        'Sign in': 'サインイン',
        'Sign Out': 'サインアウト',
        'Email': 'メール',
        'Have an account?': 'アカウントを持っています？',
        'Create a new account': '新しいアカウントを作成する',
        'Scan then enter verification code': 'スキャンして確認コードを入力します',
        'Enter Security Code:': 'セキュリティコードを入力してください：',
        'Verify Security Token': 'セキュリティトークンを確認する',
        'Account recovery requires verified contact information': 'アカウントの復旧には、確認済みの連絡先情報が必要です',
        'Phone Number': '電話番号',
        'Sign Up Failed': 'サインアップに失敗しました',
        'Confirm Sign Up Failed': 'サインアップに失敗したことを確認する',
        'Authentication Error': '認証エラー',
        'The username should either be a string or one of the sign in types': 'メールアドレスは、文字列またはサインインタイプのいずれかである必要があります',
        'Password cannot be empty': 'パスワードを空にすることはできません',
        'Confirmation code cannot be empty': '確認コードを空にすることはできません',
        'Error creating account': 'アカウントの作成中にエラーが発生しました',
        'Challenge response cannot be empty': 'チャレンジレスポンスを空にすることはできません',
        'Failed to get the session because the user is empty': 'メールアドレスが空であるため、セッションを取得できませんでした',
        'Incorrect username or password.': 'メールアドレスまたはパスワードが違います。',
        'Create Account': 'アカウントを作成する',
        '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6': '1つの検証エラーが検出されました：「パスワード」の値が制約を満たせませんでした：メンバーの長さが6以上である必要があります',
        'Invalid verification code provided, please try again.': '無効な確認コードが提供されました。再試行してください。',
        'Attempt limit exceeded, please try after some time.': '試行制限を超えました。しばらくしてから試してください。',
        'Password did not conform with policy: Password not long enough': 'パスワードがポリシーに準拠していません：パスワードの長さが足りません',
        'Attributes did not conform to the schema: email: The attribute is required': '属性がスキーマに準拠していませんでした：email：属性が必要です',
        'User is disabled.': 'メールアドレスが無効になっています。',
        'Attribute value for email must not be null': 'メールを入力してください',
        'Username should be either an email or a phone number.': 'メールアドレスは、電子メールまたは電話番号のいずれかである必要があります。',
        'An account with the given email already exists.': '指定されたメールアドレスのアカウントは既に存在します。',
    }
};

export default lan;