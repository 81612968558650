import React, {useEffect} from 'react';
import { useHash, useCookie } from 'react-use';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import AmplifySignCom from './AmplifySignCom';
import './App.css';

const Login = () => {
    const [hash, setHash] = useHash();
    const [idToken, updateIdToken, deleteIdToken] = useCookie("id_token");
    useEffect(() => {
        if (hash) {
            const query = hash.replace('#','');
            const tem = query.split('&');
            const token = tem.map((ee) => {
                if (ee) {
                    console.log('222')
                    let a = ee.split('=');
                    return a[1];
                };
                return null;
            })

            if(token !== null) {
                signOut();
            }
        } 
    }, [])
    useEffect(() => {
        const unsubscribe = onAuthUIStateChange((state, authData) => {
            if(state === AuthState.SignedIn) {
                const token = authData.signInUserSession.idToken.jwtToken;
                if(idToken === null || idToken !== token) {
                    updateIdToken(token);
                }
                setTimeout(()=>{
                    window.location.href = "https://cbredata.com/index.php#id_token="+token+'&language='+localStorage.getItem("cur_lan");
                }, 1000);
            }
        })
        
        return () => {
            unsubscribe();
        };
    }, []);

    

    async function signOut() {
        try {
            deleteIdToken();
            await Auth.signOut();
        } catch (error) {
            //console.log('error signing out: ', error);
        }
    }

    return (
        <AmplifyAuthenticator>
            <AmplifySignCom />
        </AmplifyAuthenticator>
    );
};

export default Login;
